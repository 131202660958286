var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"新增收货地址","body-style":{ color: 'red' }},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"iotplt-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"收货人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['receiver', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入收货人' },
              { max: 50, message: '最多50个字符' } ]
          }]),expression:"['receiver', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入收货人' },\n              { max: 50, message: '最多50个字符' },\n            ]\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"联系方式"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone_number', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入联系方式' },
              { pattern: /^1[3-9]\d{9}$/, message: '联系方式格式不正确' } ]
          }]),expression:"['phone_number', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入联系方式' },\n              { pattern: /^1[3-9]\\d{9}$/, message: '联系方式格式不正确' },\n            ]\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"邮编"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['post_code', {
            normalize: this.$lodash.trim
          }]),expression:"['post_code', {\n            normalize: this.$lodash.trim\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"所在地区"}},[_c('a-spin',{attrs:{"spinning":_vm.loadingRegionOption}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:(['region',{
              rules: [
                { required: true, message: '请选择所在地区' } ]
            }]),expression:"['region',{\n              rules: [\n                { required: true, message: '请选择所在地区' },\n              ]\n            }]"}],attrs:{"field-names":{ label: 'name', value: 'id', children: 'children' },"load-data":_vm.fetchRegionOptions,"show-search":{ filter: _vm.regionFilter, limit: false },"options":_vm.regionOptions,"placeholder":"请选择所在地区"}})],1)],1),_c('a-form-item',{attrs:{"label":"详细地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['address_detail', {
            normalize: this.$lodash.trim,
            rules:[
              { required: true, message: '请输入详细地址' } ]
          }]),expression:"['address_detail', {\n            normalize: this.$lodash.trim,\n            rules:[\n              { required: true, message: '请输入详细地址' },\n            ]\n          }]"}]})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }